import styled from "styled-components";

import Dropdown from "./Dropdown";

export const HomepageDropdownLeft = styled(Dropdown)`
  + .selectBox {
    background-color: var(--color-teal);
    width: 190px;
    border: none;
    padding: 10px;
    margin-top: 10px;

    span {
      color: var(--color-white);
      font-family: "Fjalla One";
      font-size: 26px;
      line-height: 100%;
      width: 93px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 1000px) {
        font-size: 16px;
      }
    }

    + .selectItems {
      width: max-content;
      min-width: 100%;
    }
  }
`;

export const HomepageDropdownRight = styled(Dropdown)`
  + .selectBox {
    background-color: var(--color-pink);
    width: 190px;
    border: none;
    padding: 10px;
    margin-top: 10px;

    span {
      color: var(--color-white);
      font-family: "Fjalla One";
      font-size: 26px;
      line-height: 100%;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (min-width: 767px) {
        width: 93px;
      }

      @media (max-width: 1000px) {
        font-size: 16px;
        width: 93px;
      }
    }

    + .selectItems {
      width: 130px;
      max-height: 200px;
      overflow: auto;

      @media (min-width: 767px) {
        width: 300px;
      }

      @media (max-width: 767px) {
        width: 130px;

        button {
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;
