import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import HomepageDropdown from "@/components/form/Dropdown/HomepageDropdown";
import { Grid } from "@/components/layout";
import { CategoryType } from "@/types/categoryTypes";
import { ColorType } from "@/types/colorTypes";
import { FirstDropdownCategoryType } from "@/types/firstDropdownCategoryTypes";

import { P } from ".";

interface TextWithDropdownsProps {
  text: string;
  textToHighlight?:
    | { word: string; color: string }
    | { word: string; color: string }[];
  color?: ColorType;
  variant?: "bold" | "regular" | "light" | "italic" | "helper";
  style?: React.CSSProperties;
  className?: string;
  categoriesFirstDropdown?: FirstDropdownCategoryType[];
  categories: CategoryType[];
  /** Event called when the dropdown changes */
  onChange?: (x: any) => void;
  textBeforeFirstDropdown?: string;
  valueSecondDropdown: string;
}

function regexReplace(
  text: string,
  searchString: string,
  highlightColor: string,
) {
  const regex = new RegExp(`${searchString}`, "gi");

  return text.replace(
    regex,
    `<span
        className="font-heading p-[15px] rounded-[8px] font-normal mx-[10px] text-white"
        style="backgroundColor: ${highlightColor}; line-height: 1;"
      >
        $&
      </span>`,
  );
}

const TextWithDropdowns: React.FC<TextWithDropdownsProps> = ({
  text,
  textToHighlight,
  variant = "light",
  color = "primary",
  style,
  className,
  categoriesFirstDropdown,
  categories,
  onChange,
  textBeforeFirstDropdown = "Mijn leeftijd is",
  valueSecondDropdown,
}) => {
  const initialFirstDropdownOption = { key: "...", value: "" };
  const [defaultFirstDropdownOption, setDefaultFirstDropdownOption] = useState(
    initialFirstDropdownOption,
  );

  const initialSecondDropdownOption = { key: "...", value: "" };
  const [defaultSecondDropdownOption, setDefaultSecondDropdownOption] =
    useState(initialFirstDropdownOption);
  const [selectedOptions, setSelectedOptions] = useState({
    category: "",
    firstDropdownCategory: "",
  });

  const [newCategories, setNewCategories] = useState<
    { name: string; value: string }[]
  >([]);

  useEffect(() => {
    if (onChange) onChange(selectedOptions);
  }, [selectedOptions]);

  let newText = text;

  if (textToHighlight && Array.isArray(textToHighlight)) {
    textToHighlight.forEach((highlight) => {
      newText = regexReplace(newText, highlight.word ?? "", highlight.color);
    });
  } else if (textToHighlight) {
    newText = regexReplace(
      newText,
      textToHighlight.word ?? "",
      textToHighlight.color,
    );
  }
  const { register } = useForm();

  const handleFirstDropdownSelection = (option: string) => {
    setSelectedOptions({
      firstDropdownCategory: option,
      category: selectedOptions.category,
    });

    // If the categoriesFirstDropdown still contains the initial option, remove it and set the default option
    if (
      categoriesFirstDropdown?.find(
        (x) =>
          x.value === initialFirstDropdownOption.value &&
          x.key === initialFirstDropdownOption.key,
      ) !== undefined
    ) {
      setDefaultFirstDropdownOption({ key: "Geen leeftijd", value: "" });
      categoriesFirstDropdown.shift();
    }
  };

  const handleCategorySelection = (option: string) => {
    setSelectedOptions({
      firstDropdownCategory: selectedOptions.firstDropdownCategory,
      category: option,
    });

    if (
      categories?.find(
        (x) =>
          x.id === initialSecondDropdownOption.value &&
          x.name === initialSecondDropdownOption.key,
      ) !== undefined
    ) {
      setDefaultSecondDropdownOption({ key: "Geen thema", value: "" });
      categories.shift();
    }
  };

  useEffect(() => {
    if (
      categoriesFirstDropdown?.find(
        (x) =>
          x.value === defaultFirstDropdownOption.value &&
          x.key === defaultFirstDropdownOption.key,
      ) === undefined
    ) {
      categoriesFirstDropdown?.unshift(defaultFirstDropdownOption);
    }
  }, [
    categoriesFirstDropdown,
    defaultFirstDropdownOption,
    handleFirstDropdownSelection,
  ]);

  useEffect(() => {
    const mappedCategories = categories?.map((category) => ({
      name: category.name,
      value: category.id,
    }));

    if (
      mappedCategories?.find(
        (x) =>
          x.value === defaultSecondDropdownOption.value &&
          x.name === defaultSecondDropdownOption.key,
      ) === undefined
    ) {
      mappedCategories?.unshift({
        name: "Geen thema",
        value: "",
      });
    }

    setNewCategories(mappedCategories ?? []);
  }, [categories, defaultSecondDropdownOption]);

  return (
    <>
      <Grid container className={"sm:hidden"}>
        <Grid item xs={8}>
          <P
            color={color}
            variant={variant}
            className={
              className +
              " header-banner-text float-right mr-[20px] text-[16px] whitespace-nowrap"
            }
          >
            {textBeforeFirstDropdown}
          </P>
        </Grid>
        <Grid item xs={4}>
          <select
            className={"homepage-dropdown-left overflow-x-hidden"}
            value={selectedOptions.firstDropdownCategory}
            onChange={(e) => handleFirstDropdownSelection(e.target.value)}
          >
            <option value={defaultFirstDropdownOption.value}>
              {defaultFirstDropdownOption.key}
            </option>
            {categoriesFirstDropdown !== null &&
              categoriesFirstDropdown
                ?.filter((x) => x.value != defaultFirstDropdownOption.value)
                .map((x) => {
                  return (
                    <option key={x.value} value={x.value}>
                      {x.key}
                    </option>
                  );
                })}
          </select>
        </Grid>
        <Grid item xs={8}>
          <P
            color={color}
            variant={variant}
            className={
              className +
              " header-banner-text float-right mr-[20px] text-[16px] whitespace-nowrap"
            }
          >
            en wil meer informatie over
          </P>
        </Grid>
        <Grid item xs={4}>
          <select
            className={"homepage-dropdown-right overflow-x-hidden"}
            value={valueSecondDropdown}
            onChange={(e) => handleCategorySelection(e.target.value)}
          >
            <option value="">...</option>
            {categories?.map((x) => {
              return (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </Grid>
      </Grid>
      <Grid container className={"hidden sm:block"}>
        <Grid item sm={12} className={"mx-auto flex w-fit"}>
          <P
            color={color}
            variant={variant}
            className={className + " header-banner-text mr-[20px] text-right"}
          >
            {textBeforeFirstDropdown}
          </P>
          <HomepageDropdown
            options={
              categoriesFirstDropdown == undefined
                ? []
                : categoriesFirstDropdown?.map((x) => {
                    return { name: x.key, value: x.value };
                  })
            }
            label=""
            name="thema"
            placeholder="..."
            order={"1"}
            onChange={handleFirstDropdownSelection}
            register={register}
            value={selectedOptions.firstDropdownCategory}
          />
          <P
            color={color}
            variant={variant}
            className={
              className + " header-banner-text mr-[20px] ml-[20px] text-right"
            }
          >
            en wil meer informatie over
          </P>
          <HomepageDropdown
            options={
              categories == undefined
                ? []
                : newCategories?.map((x) => {
                    return { name: x.name, value: x.value };
                  })
            }
            label=""
            name="thema"
            placeholder="..."
            order={"2"}
            onChange={handleCategorySelection}
            register={register}
            value={valueSecondDropdown}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TextWithDropdowns;
